[
  {
    "title": "JS Art - Pixels",
    "url": "https://joshhoegen.com/trails/",
    "icon": "star",
    "images": ["img/kaleidoscope.png"],
    "template": "live.html",
    "modules": ["kaleidescope"],
    "description": "Interactive video art",
    "order": 9
  },
  {
    "title": "JS Art - Kaleidoscope",
    "url": "https://joshhoegen.com/kaleidoscope/",
    "icon": "star",
    "images": ["img/kaleidoscope.png"],
    "template": "soundcloud",
    "description": "Interactive video art",
    "order": 8
  },
  {
    "title": "JS Art - Portal",
    "url": "https://joshhoegen.com/portal/",
    "icon": "star",
    "images": ["img/portal.png"],
    "template": "live.html",
    "modules": ["portal"],
    "description": "Interactive video art",
    "order": 7
  },
  {
    "title": "JS Art - Fractals",
    "url": "https://joshhoegen.com/fractals/",
    "icon": "star",
    "images": ["img/kaleidoscope.png"],
    "template": "soundcloud",
    "description": "Interactive video art",
    "order": 6
  },
  {
    "title": "Flickr | Art",
    "url": "http://flickr.com/photos/joshhoegen",
    "icon": "flickr",
    "images": ["img/flickr.png"],
    "modules": ["kaleidescope"],
    "description": "Visual Art",
    "order": 3
  },
  {
    "title": "Instagram | Art",
    "url": "http://instagram.com/joshhoegen",
    "icon": "instagram",
    "images": ["img/instagram.png"],
    "modules": ["kaleidescope"],
    "description": "Art and life in static images",
    "order": 2
  },
  {
    "title": "Git | Code",
    "url": "http://github.com/joshhoegen",
    "icon": "github",
    "images": ["img/github.png"],
    "description": "Sauce",
    "order": 1
  },
  {
    "title": "Sound Cloud | Music",
    "url": "http://soundcloud.com/byutifu",
    "icon": "soundcloud",
    "images": ["img/soundcloud.png"],
    "description": "Remix of Jazz and other Retro Artists",
    "order": 4
  },
  {
    "title": "LinkedIn | Pro Profile",
    "url": "http://linkedin.com/pub/josh-hoegen/b/a9/9b8",
    "icon": "linkedin",
    "images": ["img/linkedin.png"],
    "description": "Professional profile",
    "order": 5
  }
]
