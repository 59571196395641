{
  "fractals": {
    "href": "/pages/fractals/",
    "description": "Use video and audio to create unique and psychedelic video art in your browser!",
    "title": "Psychedelic Video Fractals",
    "video": null
  },
  "portal": {
    "href": "/pages/portal/",
    "description": "Don't get dizzy! Works best in the dark... or anywhere?",
    "title": "Portal"
  },
  "kaleidoscope": {
    "href": "/pages/kaleidoscope/",
    "description": "More video and audio to create situational and psychedelic video art in your browser!",
    "title": "Crunk Kaleidoscope"
  },
  "light-trails": {
    "href": "/pages/trails/",
    "description": "Create pixels or swirls from the color you choose. A nice ambient piece.",
    "title": "Pixel Trails"
  }
}
